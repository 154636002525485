<template>
  <section id="portfolio-data">
    <template v-if="currentPortfolio.name">
      <router-view />
    </template>
    <template v-else>
      <b-alert variant="info" show>
        <div class="alert-body">
          <span>Выберите портфель для отображения информации</span>
        </div>
      </b-alert>
    </template>
  </section>
</template>

<script>
import usePortfolio from '@/comp-functions/usePortfolio';

export default {
  name: 'LayoutPortfolioData',
  setup() {
    const { currentPortfolio } = usePortfolio();

    return {
      currentPortfolio,
    };
  },
};
</script>

<style></style>
